import {
    ExpandableContentComponent as SourceExpandableContentComponent,
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.override.style';

/** @namespace Steinkrueger/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends SourceExpandableContentComponent {}

export default ExpandableContentComponent;

/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-lines */

import parser from 'html-react-parser';
import {Fragment, Suspense} from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import Loader from 'Component/Loader/Loader.component';
import ProductAdvantagesCmsBlock from 'Component/ProductAdvantagesCmsBlock';
import ProductGallery from 'Component/ProductGallery';
import ProductWishlist from 'Component/ProductWishlist/ProductWishlist.container';
import {Allergen} from 'Query/ProductList.type';
import {Additives, Nutritional} from 'SourceQuery/ProductList.type';
import {
    Popup,
    ProductActions,
    ProductAttributes,
    ProductInformation,
    ProductLinks,
    ProductPageComponent as SourceProductPageComponent,
    ProductReviewForm,
    ProductReviews,
    ProductTabs,
} from 'SourceRoute/ProductPage/ProductPage.component';
import {LinkedProductType} from 'Store/LinkedProducts/LinkedProducts.type';
import {ReactElement} from 'Type/Common.type';

import {ProductPageTabs} from './ProductPage.config';
import {ProductPageTab} from './ProductPage.type';

import './ProductPage.override.style';

export {
    ProductReviews,
    ProductTabs,
    ProductAttributes,
    ProductReviewForm,
    ProductLinks,
    ProductInformation,
    Popup,
    ProductActions,
};

/** @namespace Steinkrueger/Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPageComponent {
    tabMap: Record<ProductPageTabs, ProductPageTab> = {
        [ ProductPageTabs.ATTRIBUTES ]: {
            name: __('Details'),
            shouldTabRender: (): boolean => false,
            render: (key: string): ReactElement => this.renderProductAttributesTab(key),
        },
        [ ProductPageTabs.REVIEWS ]: {
            name: __('Reviews'),
            // Return true since we always show 'Add review' button
            shouldTabRender: (): boolean => false,
            render: (key: string): ReactElement => this.renderProductReviewsTab(key),
        },
        [ ProductPageTabs.NUTRITIONAL ]: {
            name: __('Nutritional'),
            shouldTabRender: (): boolean => {
                const { activeProduct, selectedProduct } = this.props;
                const product = selectedProduct || activeProduct;
                const { table_attributes } = product;

                return !!table_attributes?.nutritional;
            },
            render: (key: string): ReactElement => this.renderProductNutritionalTab(key),
        },
        [ ProductPageTabs.ALLERGENS ]: {
            name: __('Allergens'),
            shouldTabRender: (): boolean => {
                const { activeProduct, selectedProduct } = this.props;
                const product = selectedProduct || activeProduct;
                const { table_attributes } = product;

                return !!table_attributes?.allergens;
            },
            render: (key: string): ReactElement => this.renderProductAllergensTab(key),
        },
        [ ProductPageTabs.ADDITIVES ]: {
            name: __('Additives'),
            shouldTabRender: (): boolean => {
                const { activeProduct, selectedProduct } = this.props;
                const product = selectedProduct || activeProduct;
                const { table_attributes } = product;

                return !!table_attributes?.additives;
            },
            render: (key: string): ReactElement => this.renderProductAdditivesTab(key),
        },
        [ ProductPageTabs.INFORMATION ]: {
            name: __('Produktbeschreibung'),
            shouldTabRender: (): boolean => false,
            render: (key: string): ReactElement => this.renderProductInformationTab(key),
        },
        [ ProductPageTabs.INGREDIENTS ]: {
            name: __('Information'),
            shouldTabRender: (): boolean => {
                const { activeProduct, selectedProduct } = this.props;
                const product = selectedProduct || activeProduct;
                const { attributes } = product;

                if (!attributes?.ingredients) {
                    return false;
                }

                return !!attributes?.ingredients.attribute_value;
            },
            render: (key: string): ReactElement => this.renderProductIngredientsTab(key),
        },
    };

    renderProductLabel(): ReactElement {
        const { activeProduct, selectedProduct } = this.props;
        const product = selectedProduct || activeProduct;

        if (!selectedProduct && activeProduct.variants?.length) {
            return null;
        }

        const isFreshCut = product.is_fresh_cut ? __('Fresh Cut') : '';
        const { attributes } = product;
        const general = attributes?.allgemein;

        if (!isFreshCut && !general) {
            return null;
        }

        const freshCut = !!isFreshCut;
        const generalAttrs = general?.attribute_options;
        const generalAttrsKeys = generalAttrs ? Object.keys(generalAttrs) : [];

        return (
            <div block="ProductPageLabel" elem="Wrapper">
                { freshCut && (
                    <div block="ProductPageLabel Label" elem="freshCut" key="freshCut">
                        { isFreshCut }
                    </div>
                ) }
                { generalAttrs && generalAttrsKeys.map((item) => (
                        <div
                          block="ProductPageLabel Label"
                          elem={ generalAttrs[item].label }
                          key={ generalAttrs[item].label }
                        >
                            { generalAttrs[item].label }
                        </div>
                )) }
            </div>
        );
    }

    renderProductDesktopMainData(): ReactElement {
        return (
            <>
                { this.renderProductLabel() }
                { this.renderProductName() }
            </>
        );
    }

    renderProductActions() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            setActiveProduct,
            parameters,
        } = this.props;

        return (
            <div block="ProductPage" elem="ProductActions">
                { this.renderProductDesktopMainData() }
                <Suspense fallback={ this.renderProductActionsPlaceholder() }>
                    <ProductActions
                      getLink={ getLink }
                      product={ dataSource }
                      parameters={ parameters }
                      areDetailsLoaded={ areDetailsLoaded }
                      setActiveProduct={ setActiveProduct }
                      isPDP
                    />
                </Suspense>
            </div>
        );
    }

    renderProductIngredientsTab(key: string): ReactElement {
        const { activeProduct, selectedProduct } = this.props;
        const product = selectedProduct || activeProduct;
        const { attributes } = product;
        const ingredients = attributes?.ingredients;
        const heading = __('Ingredients');

        return (
            <Suspense fallback={ <Loader /> } key={ key }>
                { ingredients && (
                    <ExpandableContent
                      heading={ heading }
                      mix={ { block: 'ProductIngredients', elem: 'Content' } }
                    >
                    { parser(ingredients.attribute_value.replace(/\n/g, '<br />')) }
                    </ExpandableContent>
                ) }
            </Suspense>
        );
    }

    renderProductAllergensTab(key: string): ReactElement {
        const { activeProduct, selectedProduct } = this.props;
        const product = selectedProduct || activeProduct;
        const { table_attributes } = product;
        const heading = __('Allergens');
        const allergens = table_attributes ? JSON.parse(table_attributes.allergens) : '';

        return (
            <Suspense fallback={ <Loader /> } key={ key }>
                { allergens && (
                    <ExpandableContent
                      heading={ heading }
                      mix={ {block: 'Allergens', elem: 'Content'} }
                    >
                        <div block="ProductTable" elem="AttributeBlock">
                            <dl block="ProductTable" elem="Attributes">
                                { allergens.map(this.renderAllergenTable.bind(this)) }
                            </dl>
                        </div>
                    </ExpandableContent>
                ) }
            </Suspense>
        );
    }

    renderProductAdditivesTab(key: string): ReactElement {
        const { activeProduct, selectedProduct } = this.props;
        const product = selectedProduct || activeProduct;
        const { table_attributes } = product;
        const heading = __('Additives');
        const additives = table_attributes ? JSON.parse(table_attributes.additives) : '';

        return (
            <Suspense fallback={ <Loader /> } key={ key }>
                { additives && (
                    <ExpandableContent
                      heading={ heading }
                      mix={ {block: 'Additives', elem: 'Content'} }
                    >
                        <div block="ProductTable" elem="AttributeBlock">
                            <dl block="ProductTable" elem="Attributes">
                                { additives.map(this.renderAdditivesTable.bind(this)) }
                            </dl>
                        </div>
                    </ExpandableContent>
                ) }
            </Suspense>
        );
    }

    renderAdditivesTable(additives: Additives): ReactElement {
        return (
            <dd block="ProductTable" elem="ValueLabel" key={ additives.additives }>
                { additives.additives }
            </dd>
        );
    }

    renderAllergenTable(allergen: Allergen): ReactElement {
        return (
            <Fragment key={ allergen.allergen }>
                <dt block="ProductTable" elem="AttributeLabel">
                    { allergen.allergen }
                </dt>
                <dd block="ProductTable" elem="ValueLabel">
                    { allergen.status }
                </dd>
            </Fragment>
        );
    }

    renderNutritionalTable(item: Nutritional): ReactElement {
        const {
            nutritional,
            qty,
            unit,
            indent,
        } = item;

        return (
            <Fragment key={ nutritional }>
                <dt block="ProductTable" elem="AttributeLabel" mods={ { indent } }>
                    { nutritional }
                </dt>
                <dd block="ProductTable" elem="ValueLabel" mods={ { indent } }>
                    { qty }
                    { unit }
                </dd>
            </Fragment>
        );
    }

    renderProductNutritionalTab(key: string): ReactElement {
        const { activeProduct, selectedProduct } = this.props;
        const product = selectedProduct || activeProduct;
        const { table_attributes } = product;
        const heading = __('Nutritional');
        const nutritional = table_attributes ? JSON.parse(table_attributes.nutritional) : '';

        return (
            <Suspense fallback={ <Loader /> } key={ key }>
                { table_attributes?.nutritional && (
                    <ExpandableContent
                      heading={ heading }
                      mix={ {block: 'Nutritional', elem: 'Content'} }
                    >
                        <div block="ProductTable" elem="AttributeBlock">
                            <dl block="ProductTable" elem="Attributes">
                                { nutritional.map(this.renderNutritionalTable.bind(this)) }
                            </dl>
                        </div>
                    </ExpandableContent>
                ) }
            </Suspense>
        );
    }

    renderProductInformationTab(key: string): ReactElement {
        const {
            dataSource: {description: {html} = {}},
            areDetailsLoaded,
        } = this.props;

        if (!html) {
            return null;
        }

        return (
            <Suspense fallback={ null } key={ key }>
                <ProductInformation
                  htmlDescription={ html }
                  areDetailsLoaded={ areDetailsLoaded }
                  key={ key }
                />
            </Suspense>
        );
    }

    renderProductWishlist(): ReactElement {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            setActiveProduct,
            parameters,
        } = this.props;

        return (
            <ProductWishlist
              getLink={ getLink }
              product={ dataSource }
              parameters={ parameters }
              areDetailsLoaded={ areDetailsLoaded }
              setActiveProduct={ setActiveProduct }
            />
        );
    }

    renderProductPageContent(): ReactElement {
        const {
            areDetailsLoaded,
            activeProduct,
            useEmptyGallerySwitcher,
            isVariant,
        } = this.props;

        return (
            <>
                { this.renderProductActions() }
                <div block="ProductPage" elem="ProductGalleryContainer">
                    { this.renderProductWishlist() }
                    <ProductGallery
                      product={ activeProduct }
                      areDetailsLoaded={ areDetailsLoaded }
                      isWithEmptySwitcher={ useEmptyGallerySwitcher }
                      showLoader={ isVariant }
                    />
                </div>
            </>
        );
    }

    renderProductAdvantagesSection(): ReactElement {
        return (
            <Suspense fallback={ <Loader /> }>
                <ProductAdvantagesCmsBlock identifier="product_advantages" />
            </Suspense>
        );
    }

    renderAdditionalSections(): ReactElement {
        const {
            areDetailsLoaded,
        } = this.props;

        return (
            <>
                { this.renderProductTabs() }
                <Suspense fallback={ null }>
                    <ProductLinks
                      linkType={ LinkedProductType.RELATED }
                      title={ __('Customers also bought') }
                      areDetailsLoaded={ areDetailsLoaded }
                    />
                </Suspense>
                <Suspense fallback={ null }>
                    <ProductLinks
                      linkType={ LinkedProductType.UPSELL }
                      title={ __('More from this category') }
                      areDetailsLoaded={ areDetailsLoaded }
                    />
                </Suspense>
            </>
        );
    }
}

export default ProductPageComponent;
